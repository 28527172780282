<template>
  <div class="about-view">
    <h1 class="text-3xl font-bold mb-6">About Slow News in Thai</h1>
    <p class="mb-4">slowthai.news is a platform designed to help learners of the Thai language improve their reading and listening skills through daily news summaries.</p>
    <p class="mb-4">Our mission is to make Thai news accessible and comprehensible for language learners at various levels.</p>
    <p>We provide daily summaries of top news stories from Thailand, complete with audio recordings, English translations, and key vocabulary (soon) to aid in your learning journey.</p>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>