<template>
    <div class="audio-player">
      <div v-if="loading" class="loading">Loading audio...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else class="player-controls">
        <button @click="togglePlay" class="play-pause-btn">
          <i :class="isPlaying ? 'fas fa-pause' : 'fas fa-play'"></i>
        </button>
        <div class="progress-container">
          <input
            type="range"
            min="0"
            :max="duration"
            step="0.1"
            v-model="currentTime"
            @input="seek"
            class="progress-bar"
          >
          <div class="time-display">
            {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
          </div>
        </div>
        <select v-model="playbackRate" @change="changePlaybackRate" class="playback-speed">
          <option v-for="rate in playbackRates" :key="rate" :value="rate">
            {{ rate }}x
          </option>
        </select>
      </div>
      <audio
        ref="audioElement"
        :src="src"
        @loadedmetadata="onLoadedMetadata"
        @timeupdate="onTimeUpdate"
        @ended="onEnded"
        @error="onError"
      ></audio>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AudioPlayer',
    props: {
      src: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isPlaying: false,
        duration: 0,
        currentTime: 0,
        loading: true,
        error: null,
        playbackRate: 1,
        playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2]
      }
    },
    methods: {
      togglePlay() {
        if (this.isPlaying) {
          this.$refs.audioElement.pause()
        } else {
          this.$refs.audioElement.play()
        }
        this.isPlaying = !this.isPlaying
      },
      onLoadedMetadata() {
        this.duration = this.$refs.audioElement.duration
        this.loading = false
      },
      onTimeUpdate() {
        this.currentTime = this.$refs.audioElement.currentTime
      },
      onEnded() {
        this.isPlaying = false
        this.currentTime = 0
      },
      seek() {
        this.$refs.audioElement.currentTime = this.currentTime
      },
      changePlaybackRate() {
        this.$refs.audioElement.playbackRate = this.playbackRate
      },
      formatTime(time) {
        const minutes = Math.floor(time / 60)
        const seconds = Math.floor(time % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
      },
      onError(e) {
        console.error('Audio error:', e)
        this.error = 'Failed to load audio. Please try again later.'
        this.loading = false
      }
    },
    watch: {
      src: {
        immediate: true,
        handler(newSrc) {
          if (newSrc) {
            this.loading = true
            this.error = null
            this.$nextTick(() => {
              this.$refs.audioElement.load()
            })
          } else {
            this.loading = false
            this.error = 'No audio source provided'
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .audio-player {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .player-controls {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .play-pause-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .play-pause-btn:hover {
    background-color: #45a049;
  }
  
  .progress-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .progress-bar {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: #ddd;
    outline: none;
    border-radius: 3px;
    transition: background 0.2s ease;
  }
  
  .progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .progress-bar::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .time-display {
    font-size: 0.8em;
    color: #666;
  }
  
  .playback-speed {
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 0.9em;
  }
  
  .loading, .error {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  .error {
    color: #d32f2f;
  }
  </style>