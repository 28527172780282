<template>
  <div class="contact-view">
    <h1 class="text-3xl font-bold mb-6">Contact Us</h1>
    <p class="mb-4">I'd love to hear from you! If you have any questions, suggestions, or feedback, please don't hesitate to reach out.</p>
    <a href="mailto:pat@slowthai.news" class="bg-blue-500 text-white px-4 py-2 rounded inline-block">Drop me an email</a>
  </div>
</template>

<script>
export default {
name: 'ContactView'
}
</script>