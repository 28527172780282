<template>
  <div class="subscriber-capture bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 text-center mt-4">
    <h3 class="text-xl font-semibold mb-2">Get Daily News Updates</h3>
    <p class="text-gray-600 dark:text-gray-400 mb-4">Stay informed with our daily Thai news summaries.</p>
    
    <form @submit.prevent="subscribeUser" class="mb-4">
      <input 
        v-model="email" 
        type="email" 
        placeholder="Enter your email" 
        required
        class="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:border-blue-500"
      >
      <button 
        type="submit" 
        class="mt-2 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Stay Informed
      </button>
    </form>
    <p v-if="message" :class="{'text-green-500': !error, 'text-red-500': error}">{{ message }}</p>

    <div class="mt-4">
      <p class="text-sm text-gray-600 dark:text-gray-400 mb-2">Follow us on:</p>
      <div class="flex justify-center space-x-4">
        <a href="#" class="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
          <i class="fab fa-whatsapp fa-lg"></i>
        </a>
        <a href="#" class="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
          <i class="fab fa-line fa-lg"></i>
        </a>
        <a href="#" class="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
          <i class="fab fa-instagram fa-lg"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SubscriberCapture',
  data() {
    return {
      email: '',
      message: '',
      error: false
    }
  },
  methods: {
    async subscribeUser() {
      try {
        const response = await axios.post('/api/subscribe', { email: this.email });
        this.message = response.data.message;
        this.error = false;
        this.email = '';
      } catch (error) {
        this.message = error.response.data.message || 'An error occurred. Please try again.';
        this.error = true;
      }
    }
  }
}
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');
</style>