<template>
  <div id="app" :class="{ 'dark': isDarkMode }">
    <div class="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <header class="bg-white dark:bg-gray-800 shadow-sm">
        <div class="max-w-5xl mx-auto px-4 py-3 flex flex-wrap justify-between items-center">
          <router-link to="/" class="text-2xl font-bold mb-2 sm:mb-0">
            <span class="text-blue-600 dark:text-blue-400">slowthai</span><span class="text-green-600 dark:text-green-400">.news</span>
          </router-link>
          <nav class="flex flex-wrap items-center space-x-2 sm:space-x-4">
            <router-link to="/about" class="hover:text-blue-600 dark:hover:text-blue-400 text-sm sm:text-base">About</router-link>
            <router-link to="/resources" class="hover:text-blue-600 dark:hover:text-blue-400 text-sm sm:text-base">Resources</router-link>
            <router-link to="/contact" class="hover:text-blue-600 dark:hover:text-blue-400 text-sm sm:text-base">Contact</router-link>
            <router-link to="/support" class="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1 rounded-full text-sm">Support</router-link>
            <button @click="toggleDarkMode" class="ml-2 p-1 rounded-full bg-gray-200 dark:bg-gray-700">
              <svg v-if="isDarkMode" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
              </svg>
            </button>
          </nav>
        </div>
      </header>
      
      <!-- New announcement section -->
      <div v-if="announcement" class="bg-gray-200 dark:bg-gray-700 text-sm">
        <div class="max-w-5xl mx-auto px-4 py-2 text-center">
          {{ announcement }}
        </div>
      </div>

      <main class="max-w-5xl mx-auto px-4 py-8">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  data() {
    return {
      isDarkMode: false,
      announcement: 'slowthai.news is now live. romanization text feature is coming soon. stay tuned.'
    }
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode
      localStorage.setItem('darkMode', this.isDarkMode.toString())
      document.documentElement.classList.toggle('dark', this.isDarkMode)
    }
  },
  mounted() {
    const savedDarkMode = localStorage.getItem('darkMode')
    if (savedDarkMode !== null) {
      this.isDarkMode = JSON.parse(savedDarkMode)
      document.documentElement.classList.toggle('dark', this.isDarkMode)
    }
  }
}
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.dark .content {
  background-color: #1f2937;
  color: #e5e7eb;
}

.dark h2 {
  color: #e5e7eb;
}
</style>