<template>
  <div class="resources-view">
    <h1 class="text-3xl font-bold mb-6">Resources</h1>
    <p class="mb-4">Here you can find additional resources to help you in your Thai language learning journey.</p>
    <ul class="list-disc list-inside">
      <li class="mb-2">Thai language textbooks</li>
      <li class="mb-2">Online Thai courses</li>
      <li class="mb-2">Thai language podcasts</li>
      <li class="mb-2">Thai-English dictionaries</li>
      <li class="mb-2">Thai culture and history books</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ResourcesView'
}
</script>