import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css'  // Changed from './assets/tailwind.css'
import analyticsPlugin from './plugins/analytics'

const app = createApp(App)
app.use(router)
app.use(analyticsPlugin)
app.mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered: ', registration)
      })
      .catch(registrationError => {
        console.log('Service Worker registration failed: ', registrationError)
      })
  })
}