<template>
  <div class="daily-summary">
    <h2 class="text-2xl font-bold mb-4">Daily Summary</h2>
    <p class="text-xl mb-4">{{ formattedDate }}</p>
    <div v-if="loading" class="flex items-center justify-center py-8">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      <span class="ml-3 text-lg text-gray-600">Loading daily summary...</span>
    </div>
    <div v-else-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Error:</strong>
      <span class="block sm:inline">{{ error }}</span>
    </div>
    <div v-else-if="debugInfo" class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative" role="alert">
      <strong class="font-bold">Debug Info:</strong>
      <pre class="mt-2 whitespace-pre-wrap">{{ JSON.stringify(debugInfo, null, 2) }}</pre>
    </div>
    <div v-else>
      <div class="audio-player-container">
        <AudioPlayer :src="audioFilename" />
      </div>
      
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Thai Content</h3>
        <div v-html="thaiContent" class="thai-content text-lg leading-relaxed"></div>
      </div>
      
      <div class="mb-6">
        <h3 class="text-xl font-semibold mb-2">English Summary</h3>
        <div class="english-summary text-lg leading-relaxed">
          <p v-for="(paragraph, index) in englishSummaryParagraphs" :key="index" class="mb-2">
            {{ paragraph }}
          </p>
        </div>
      </div>
      
      <div v-if="keyVocabulary.length > 0" class="mb-6">
        <h3 class="text-xl font-semibold mb-2">Key Vocabulary</h3>
        <ul class="list-disc list-inside">
          <li v-for="(word, index) in keyVocabulary" :key="index" class="mb-1">
            <span class="font-semibold">{{ word.thai }}</span> - {{ word.english }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AudioPlayer from '@/components/AudioPlayer.vue';

export default {
  name: 'DailySummary',
  components: {
    AudioPlayer
  },
  data() {
    return {
      loading: true,
      error: null,
      debugInfo: null,
      audioFilename: '',
      thaiContent: '',
      englishSummary: '',
      keyVocabulary: [],
      englishSummaryParagraphs: [],
      currentDate: new Date()
    };
  },
  computed: {
    formattedDate() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return this.currentDate.toLocaleDateString('en-US', options);
    }
  },
  mounted() {
    this.fetchDailySummary();
  },
  methods: {
    async fetchDailySummary() {
      this.loading = true;
      this.error = null;
      this.debugInfo = null;
      try {
        const response = await axios.get('/api/daily-summary');
        console.log('API response:', response.data);
        this.audioFilename = response.data.audio_filename; // This should now be a full URL
        this.thaiContent = response.data.thai_content.replace(/\n/g, '<br>');
        this.englishSummary = response.data.summary;
        this.englishSummaryParagraphs = this.englishSummary.split('\n').filter(paragraph => paragraph.trim() !== '');
        this.keyVocabulary = response.data.key_vocabulary || [];
        this.loading = false;
        
        if (!this.audioFilename || !this.thaiContent || !this.englishSummary) {
          this.debugInfo = {
            audioFilename: this.audioFilename,
            thaiContent: this.thaiContent,
            englishSummary: this.englishSummary,
            keyVocabulary: this.keyVocabulary
          };
        }
      } catch (error) {
        console.error('Error fetching daily summary:', error);
        this.error = 'Failed to load daily summary. Please try again later.';
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.daily-summary {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.thai-content {
  font-family: 'Sarabun', sans-serif;
}

@media (max-width: 640px) {
  .daily-summary {
    padding: 10px;
  }
}

.audio-player-container {
  margin-bottom: 20px;
  max-width: 600px; /* Adjust based on your layout */
  margin-left: auto;
  margin-right: auto;
}
</style>