<template>
    <div class="support-view">
      <h1 class="text-3xl font-bold mb-6">Support SLOWTHAI.NEWS</h1>
      <p class="mb-4">Your support helps STN continue providing high-quality Thai language learning content.</p>
      <div class="bg-gray-100 p-6 rounded-lg">
        <h2 class="text-2xl font-semibold mb-4">Ways to Support</h2>
        <ul class="list-disc list-inside">
          <li class="mb-2">Subscribe to slowthai.news mailing list</li>
          <li class="mb-2">Share slowthai.news with your friends and family</li>
          <li class="mb-2">Provide feedback to help us improve</li>
        </ul>
        <button class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Support Now
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SupportView'
  }
  </script>