<template>
  <div class="home">
    <h1 class="text-3xl font-bold mb-6">{{ formattedDate }}</h1>
    <div class="content-wrapper">
      <div class="main-content">
        <DailySummary />
      </div>
      <aside class="sidebar">
        <StreakCounter />
        <SubscriberCapture />
      </aside>
    </div>
  </div>
</template>

<script>
import DailySummary from '../components/DailySummary.vue'
import StreakCounter from '../components/StreakCounter.vue'
import SubscriberCapture from '../components/SubscriberCapture.vue'

export default {
  name: 'HomeView',
  components: {
    DailySummary,
    StreakCounter,
    SubscriberCapture
  },
  data() {
    return {
      currentDate: new Date()
    }
  },
  computed: {
    formattedDate() {
      return this.currentDate.toLocaleDateString('th-TH');
    }
  }
}
</script>

<style scoped>
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
}
.content-wrapper {
  display: flex;
  gap: 20px;
}
.main-content {
  flex: 1;
}
.sidebar {
  width: 300px;
}
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }
  .sidebar {
    width: 100%;
  }
}
</style>
