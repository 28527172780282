<template>
    <div class="streak-counter bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6 text-center">
      <h3 class="text-xl font-semibold mb-2">Your Learning Streak</h3>
      <p class="streak text-4xl font-bold text-blue-600 dark:text-blue-400 mb-2">{{ streak }} days</p>
      <p class="motivation text-gray-600 dark:text-gray-400">{{ motivationMessage }}</p>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        streak: 0,
        motivationMessage: ''
      }
    },
    mounted() {
      this.checkStreak();
    },
    methods: {
      checkStreak() {
        const today = new Date().toDateString();
        const lastVisit = localStorage.getItem('lastVisit');
        
        if (lastVisit === today) {
          this.streak = parseInt(localStorage.getItem('streak') || '0');
        } else if (new Date(lastVisit).toDateString() === new Date(Date.now() - 86400000).toDateString()) {
          this.streak = parseInt(localStorage.getItem('streak') || '0') + 1;
        } else {
          this.streak = 1;
        }
        
        localStorage.setItem('lastVisit', today);
        localStorage.setItem('streak', this.streak.toString());
        
        this.setMotivationMessage();
      },
      setMotivationMessage() {
        if (this.streak < 3) {
          this.motivationMessage = "Great start! Keep it up!";
        } else if (this.streak < 7) {
          this.motivationMessage = "You're on a roll! Don't break the chain!";
        } else {
          this.motivationMessage = "Impressive dedication! You're becoming a Thai language master!";
        }
      }
    }
  }
  </script>